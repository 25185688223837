<template>
	<div class="app-container">		
		<el-button type="primary" :loading="buttonloading" class="mt-3 mb-3 float-right" v-if="permissionList.includes(permission.add)" v-b-modal.addRow icon="el-icon-plus">{{$t('button.add')}}</el-button>
		<el-table :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row>
            <el-table-column v-for="title in ajaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
                <template slot="header">
                    <p class="search-label">{{title.label}}</p>
                    <b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
                </template>
            </el-table-column>
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <!-- <el-button type="success" :loading="buttonloading" @click="getPreviewRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.preview')}}</el-button> -->
                    <el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.edit')}}</el-button>
                    <el-button type="warning" :loading="buttonloading" @click="getEditTitleRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('menu.front_content_title')}}</el-button>
                    <el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.delete)" icon="el-icon-delete">{{$t('button.delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>

		<pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="pagination"/>
		
		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>

        <b-modal id="addRow" :title="$t('menu.front_content_add')" size="lg" hide-footer @hide="clearDataList()">
			<el-form>
				<b-row>
					<b-col cols="6">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_code')}}</template>
							<b-form-input v-model="dataList.code"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_menu')}}</template>
							<el-select v-model="dataList.menu_id" :placeholder="$t('msg.msg_select')" class="w-100"  filterable>
								<el-option v-for="(list,index) in menuList" :key="index" :label="list.name" :value="list.id">{{list.name}}</el-option>
							</el-select>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_style')}}</template>
							<b-form-textarea v-model="dataList.style" rows="2" max-rows="4"></b-form-textarea>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">Class</template>
							<b-form-textarea v-model="dataList.class" rows="2" max-rows="4"></b-form-textarea>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_sort')}}</template>
							<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_show_title')}}</template>
							<el-switch v-model="dataList.show_title" active-value="1" inactive-value="0"> </el-switch>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_status')}}</template>
							<el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
						</b-form-group>
						<b-form-group label-cols="6" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_security')}}</template>
							<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</el-form>
			
			<el-tabs class="mb-3">
				<el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
					<b-form-group label-cols="12" label-cols-lg="4">
						<template slot="label">{{$t('admin_general.table_name')}}</template>
						<b-form-input v-model="dataList.name[item.id]" :disabled="loading"></b-form-input>
					</b-form-group>
				</el-tab-pane>
			</el-tabs>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('addRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="addRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="editRow" :title="$t('menu.front_content_edit')" size="lg" hide-footer @hide="clearDataList()">
			<!-- <el-form @keyup.enter.native="editRow()" > -->
			<el-form>
                <b-row>
					<b-col cols="6">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_code')}}</template>
							<b-form-input v-model="dataList.code"></b-form-input>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_menu')}}</template>
							<el-select v-model="dataList.menu_id" :placeholder="$t('msg.msg_select')" class="w-100"  filterable>
								<el-option v-for="(list,index) in menuList" :key="index" :label="list.name" :value="list.id">{{list.name}}</el-option>
							</el-select>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_style')}}</template>
							<b-form-textarea v-model="dataList.style" rows="2" max-rows="4"></b-form-textarea>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">Class</template>
							<b-form-textarea v-model="dataList.class" rows="2" max-rows="4"></b-form-textarea>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_sort')}}</template>
							<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_show_title')}}</template>
							<el-switch v-model="dataList.show_title" active-value="1" inactive-value="0"> </el-switch>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_status')}}</template>
							<el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
						</b-form-group>
						<b-form-group label-cols="6" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_security')}}</template>
							<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</el-form>

			<el-tabs class="mb-3">
				<el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
					<b-form-group label-cols="12" label-cols-lg="4">
						<template slot="label">{{$t('admin_general.table_name')}}</template>
						<b-form-input v-model="dataList.name[item.id]" :disabled="loading"></b-form-input>
					</b-form-group>
				</el-tab-pane>
			</el-tabs>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('editRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="editRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="titleRow" :title="$t('menu.front_content_title')" hide-footer @hide="clearTitleList()">
			<el-form  >
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_style')}}</template>
							<b-form-textarea v-model="titleList.title_style" rows="2" max-rows="4"></b-form-textarea>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">Class</template>
							<b-form-textarea v-model="titleList.title_class" rows="2" max-rows="4"></b-form-textarea>
						</b-form-group>
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_show_title')}}</template>
							<el-switch v-model="titleList.show_title" active-value="1" inactive-value="0"> </el-switch>
						</b-form-group>
						<b-form-group label-cols="6" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_security')}}</template>
							<b-form-input v-model="titleList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
						</b-form-group>
			</el-form>
			
			<el-tabs class="mb-3">
				<el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
					<b-form-group label-cols="12" label-cols-lg="4">
						<template slot="label">{{$t('admin_general.table_name')}}</template>
						<b-form-input v-model="titleList.name[item.id]" :disabled="loading"></b-form-input>
					</b-form-group>
				</el-tab-pane>
			</el-tabs>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('titleRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="editTitleRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>

		<b-modal id="previewRow" :title="$t('menu.front_content_preview')" size="lg" >
			<el-tabs class="mb-3">
				<el-tab-pane style="height:200px;" v-for="language in languageList" :key="language.id" :item="language" :label="language.name">

				</el-tab-pane>
			</el-tabs>
        </b-modal>

	</div>

</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import pagination from '@/components/pagination';
import backtotop from '@/components/backtotop';

let searchForm = {
	pagination: 1,
	limit: 10,
	id: '',
	category:'',
	code:'',
	menu_name:'',
	sort:'',
	status:'',
}
			
export default{
	components: { pagination, backtotop },
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			tableData: [],
			total: 0,
			errors: [],
			submitForm: {
				id:'',
				security:'',
			},
			postData: {
				data: '',
				language: '',
			},
			searchData:Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles:[{
                prop: "id",
                label: this.$t('admin_general.table_id'),
                width:'50'
            },{
                prop: "code",
                label: this.$t('admin_general.table_code'),
                width:'100'
			},{
            //     prop: "category",
            //     label: this.$t('admin_general.table_category'),
            //     width:'100'
			// },{
                prop: "menu_name",
                label: this.$t('admin_general.table_menu'),
                width:'100'
			},{
                prop: "sort",
                label: this.$t('admin_general.table_sort'),
                width:'60'
			},{
                prop: "status",
                label: this.$t('admin_general.table_status'),
                width:'60'
			}],
			menuList: [],
			categoryList: [],
			dataList:{
				code:'',
				// show_title:0,
				menu_id:'',
				sort:'',
                status:'',
                security:'',
				name:[]
			},
			titleList:{
				show_title:0,
				title_style:'',
				title_class:'',
				security:'',
				name:[]
			},
			permission:{
                ajaxTable:'NLNEI6',
                add:'2IPXOR',
                preview:'2IPXOR',
                DBadd:'5XIYKK',
                edit:'78DPZ7',
                DBedit:'RAIPIY',
                delete:'J8ZCBH',
                DBdelete:'CTYCN5'
            },
            permissionList:[],
            languageList:[]
		}
	}, methods: {
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var result = postMethod('front/content/ajaxTable',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.tableData = data.datatable.data;
						this.menuList = data.menuList;
						// this.categoryList = data.categoryList;
						this.total = parseInt(data.datatable.total_number);
						this.listQuery.page = parseInt(data.datatable.current_pagination);
						this.listQuery.limit = parseInt(data.datatable.limit);
						this.buttonloading = false;
						this.loading = false;
					}
				});
			}
		},clearDataList(){
			this.dataList.code = '';
			this.dataList.menu_id = '';
			this.dataList.show_title = 0;
			this.dataList.sort = 10;
			this.dataList.status = '';
			this.dataList.security = '';
			this.dataList.style = '';
			this.dataList.class = '';
			this.dataList.name = [];
		},clearTitleList(){
			this.titleList.title_sytle = '';
			this.titleList.title_class = '';
			this.dataList.show_title = 0;
			this.dataList.security = 0;
			this.dataList.name = [];
		},addRow(){
			if(this.permissionList.includes(this.permission.DBadd)){
				this.preloader(true);
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var result = postMethod('front/content/DBadd',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('addRow');
						this.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},getEditRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/content/edit',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.dataList = data.thisDetail;
						this.$bvModal.show('editRow');
					}
					this.buttonloading = false;
				});
			}
		},editRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var result = postMethod('front/content/DBedit',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('editRow');
						this.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},deleteRow(id){
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = '';
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod('front/content/DBdelete',this.postData);
					result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));
						if(value.valid){
							this.$message({
							type: 'success',
							message: data.returnMsg
							});
							this.buttonloading = false;
							this.initial();
						}else{
							this.errors = data.returnMsg;
							
							this.errors.forEach(function (value){
							text+= value+"<br/>";
							});
							
							this.$method.popupErrorMessage(this, text);
							this.buttonloading = false;
						}
					
					});
				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},getEditTitleRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/content/edit',this.postData);
				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.titleList = data.thisDetail;
						this.$bvModal.show('titleRow');
					}
					this.buttonloading = false;
				});
			}
		},editTitleRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.titleList));
				var text = '';
				var result = postMethod('front/content/DBtitle',this.postData);

				result.then((value) =>{
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						this.$bvModal.hide('titleRow');
						this.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						this.$method.popupErrorMessage(this,text);
					}
					
					this.buttonloading = false;
					this.preloader(false);
				});
			}
		},getPreviewRow(){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = 1;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('front/content/preview',this.postData);
				result.then((value) =>{
					// var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						this.$bvModal.show('titleRow');
					}
					this.buttonloading = false;
				});
			}
		},pagination(){
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},expandRow(row){
            this.$refs.tableTest.toggleRowExpansion(row);
        },expandChange(row,expandedRows){
            if(expandedRows.length>1){
                this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
            }
        },saveData(){
			searchForm = this.searchData;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
        this.languageList = JSON.parse(getLocalStorage('languageList'));
        this.initial();
	}
}
</script>